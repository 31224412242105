require('./bootstrap');
import 'bootstrap/js/dist/collapse'
import Vue from 'vue'
window.Vue = require('vue');

window.moment = require('moment')

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import Datepicker from 'vuejs-datepicker';
import {en, hu, de} from 'vuejs-datepicker/dist/locale'
// Vue.use(Datepicker)
Vue.component('Datepicker', Datepicker)

// import 'lightgallery.js';

import LightGallery from 'vue-light-gallery';
 
Vue.use(LightGallery);

